<template>
  <Main :channel="20210412035" />
</template>

<script>
import Main from './Main.vue'
export default {
  components: {
    Main
  }
}
</script>